
import Vue from 'vue';
import USER from '@/store/modules/UserModule';
import UTILS from '@/store/modules/UtilityModule';
import { routesNames } from '@/router';

import LoginForm from '@/components/auth/LoginForm.vue';

export default Vue.extend({
	name: 'Login',

	components: { LoginForm },

	head() {
		return {
			title: this.$t('actions.login') as string
		};
	},

	computed: {
		USER: () => USER,
		UTILS: () => UTILS,

		isLoggedIn(): boolean {
			return this.USER.is_logged_in;
		},

		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},
		titleClass() {
			return this.isMobile ? 'px-2 mb-2' : 'mb-2 px-16';
		},
		spanClass() {
			return this.isMobile ? '' : 'px-16';
		},
		sheetClass() {
			return this.isMobile ? 'pa-2 pt-2 mb-16 pb-16' : '';
		}
	},

	methods: {
		redirect() {
			let redirect = this.$route.query.redirect;

			redirect = redirect || '/';
			this.$router.replace({ path: redirect as string });
		},

		toHome(): void {
			this.$router.replace({ name: routesNames.home });
		},

		async logout(): Promise<void> {
			this.UTILS.setPageLoader(true);
			await this.USER.LOGOUT();
			this.UTILS.setPageLoader(false);
		}
	}
});
