var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-sheet',{class:['max-auto', 'mx-auto', 'fading-from-bottom', _vm.sheetClass],attrs:{"color":"grey lighten-4","height":"100%","max-width":"1440px"}},[_c('v-layout',{attrs:{"align-center":"","justify-center":"","fill-height":""}},[(_vm.isLoggedIn)?_c('div',{staticClass:"pa-6 text-center"},[_c('h1',{class:[
					'title-spartan',
					'text-center',
					'grey--text',
					'text--darken-4',
					'spartan-font',
					'font-weight-bold',
					_vm.titleClass
				]},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('user.greetings.placeholder') + ' ' + _vm.USER.data.firstname)+"\n\t\t\t")]),_vm._v(" "),_c('span',{class:[
					'mx-auto',
					'text-center',
					'grey--text',
					'text--darken-1',
					'my-3',
					'mb-10',
					_vm.spanClass
				],staticStyle:{"font-size":"1rem"},domProps:{"innerHTML":_vm._s(_vm.$t('booking.summary.logged_in_as', { email: _vm.USER.data.email }))}}),_vm._v(" "),_c('div',{staticClass:"mt-6"},[_c('v-btn',{staticClass:"white--text pa-6 spartan-font font-weight-bold",staticStyle:{"font-size":"0.8rem"},attrs:{"elevation":"0","color":"orange123"},on:{"click":function($event){$event.stopPropagation();return _vm.toHome.apply(null, arguments)}}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('actions.continue'))+"\n\t\t\t\t")])],1),_vm._v(" "),_c('div',{staticClass:"mt-3"},[_c('v-btn',{staticClass:"pa-6 spartan-font font-weight-bold",staticStyle:{"font-size":"0.8rem"},attrs:{"elevation":"0","text":"","color":"grey"},on:{"click":function($event){$event.stopPropagation();return _vm.logout.apply(null, arguments)}}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('actions.logout'))+"\n\t\t\t\t")])],1)]):_vm._e(),_vm._v(" "),(!_vm.isLoggedIn)?_c('div',[_c('login-form',{on:{"success":_vm.redirect}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }